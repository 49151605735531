import React from "react";
import { TimelineLite, TweenLite } from "gsap";
import Player from "react-player";
import styled from "styled-components";
import useMedia from "use-media";

import useLoading, { useLoadingState } from "../../utility/useLoading";
import useEntryAnimation from "../../utility/useEntryAnimation";

import FullHeightBox from "../../components/full-height-box";
import Button from "../../components/button";
import { Title } from "../../components/text";

import { Wrapper } from "./components";

import content from "../../content/home.yml";

function ScrollAffordance() {
  return (
    <div
      css={`
        text-align: center;

        .scrollwheel {
          animation: miao 1.5s infinite;
          transform-origin: bottom;

          top: 5px;
          position: absolute;
          left: 11px;

          width: 1px;
          height: 4px;

          background-color: white;
        }

        @keyframes miao {
          0% {
            transform: translateY(0) scaleY(1);
          }

          50% {
            transform: translateY(80%) scaleY(0);
          }

          50.1% {
            transform: translateY(80%) scaleY(0);
            opacity: 1;
          }

          99% {
            transform: translateY(80%) scaleY(0);
            opacity: 0;
          }
        }
      `}
    >
      <div
        css={`
          margin-bottom: 1rem;
          font-size: 12px;
          letter-spacing: 1px;
        `}
      >
        <small>SCROLL</small>
      </div>

      <div
        css={`
          position: relative;
          width: 24px;
          margin: 0 auto;
        `}
      >
        <svg
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            fill="#ffffff"
            stroke="#ffffff"
            strokeLinecap="square"
            strokeWidth="1"
          >
            <path
              d="M12,23h0a8,8,0,0,1-8-8V9a8,8,0,0,1,8-8h0a8,8,0,0,1,8,8v6A8,8,0,0,1,12,23Z"
              fill="none"
              stroke="#ffffff"
            />
          </g>
        </svg>

        <div className="scrollwheel" />
      </div>
    </div>
  );
}

function VideoThing({ className }) {
  const [onVideoStartsLoading, onVideoReady] = useLoading("video");

  React.useEffect(() => {
    onVideoStartsLoading();
  }, []);

  function handleVideoReady() {
    onVideoReady();
  }

  return (
    <div
      className={className}
      css={`
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
      `}
    >
      <Player
        playing={true}
        loop={true}
        volume={0}
        muted
        controls={false}
        playsinline
        width={"100%"}
        height={"100%"}
        onReady={handleVideoReady}
        css={`
          width: 100%;
          height: 100%;
          video {
            object-fit: cover;
          }
        `}
        url={
          "https://primisgroup-web.s3.eu-central-1.amazonaws.com/video/hero.webm?a=123"
        }
      />
    </div>
  );
}

export function useScrollAnimation($container) {
  const _mainTL = React.useRef();

  React.useLayoutEffect(() => {
    _mainTL.current = new TimelineLite();

    _mainTL.current
      .pause()
      .fromTo(
        $container.current.querySelector(".curtain"),
        1,
        {
          opacity: 0.8,
          blur: 0,
        },
        {
          opacity: 0,
          blur: 1,
        }
      )
      .fromTo(
        $container.current.querySelector(".scroll-affordance"),
        1,
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
        0
      )
      .fromTo(
        $container.current.querySelector(".text-content"),
        1,
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
        0
      );
  });

  React.useLayoutEffect(() => {
    function handleScroll(e) {
      const progress =
        parseInt((window.scrollY / (window.innerHeight * 0.75)) * 100, 10) /
        100;

      const totalProgress =
        parseInt((window.scrollY / window.innerHeight) * 100, 10) / 100;

      TweenLite.to(_mainTL.current, 0.3, { progress });

      if (totalProgress > 1) {
        TweenLite.set($container.current.querySelector(".video-container"), {
          position: "absolute",
          top: "100vh",
        });
      } else {
        TweenLite.set($container.current.querySelector(".video-container"), {
          position: "fixed",
          top: "0",
        });
      }
    }

    _mainTL.current.pause().progress(0);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return null;
}

function useHeroAnimation() {
  const { isPageReady } = useLoadingState();

  const $container = React.useRef();
  const [animateContainerIn] = useEntryAnimation($container);

  useScrollAnimation($container);

  React.useEffect(() => {
    if (isPageReady) {
      animateContainerIn();
      animateVideoIn();
    }
  }, [isPageReady]);

  const videoTimeline = React.useRef({});

  React.useLayoutEffect(() => {
    videoTimeline.current = new TimelineLite();

    videoTimeline.current.fromTo(
      $container.current.querySelector(".video-container"),
      4,
      {
        transformOrigin: "center",
        scale: 1.65,
      },
      {
        scale: 1,
      }
    );
  }, [$container, videoTimeline]);

  const animateVideoIn = React.useCallback(
    function animateVideoIn() {
      videoTimeline.current.play();
    },
    [videoTimeline]
  );

  return { $container };
}

function HeroContent() {
  return (
    <FullHeightBox>
      <Wrapper>
        <div
          css={`
            max-width: 1024px;
            margin: 0 auto;
          `}
          className="text-content"
        >
          <Title
            data-reveal="letters"
            className="title"
            dangerouslySetInnerHTML={{
              __html: content[process.env.GATSBY_LANG].hero.title,
            }}
          ></Title>

          <Button data-reveal as={"a"} href={"#applications"}>
            {content[process.env.GATSBY_LANG].hero.applications}
          </Button>
        </div>

        <div
          className="scroll-affordance"
          css={`
            position: absolute;
            bottom: 2rem;
            left: 50%;
            transform: translateX(-50%);
            z-index: 4;
          `}
        >
          <ScrollAffordance />
        </div>
      </Wrapper>
    </FullHeightBox>
  );
}

function MobileHero() {
  const timeline = React.useRef(new TimelineLite());
  const $container = React.useRef();
  const [animateContainerIn] = useEntryAnimation($container);

  const { isPageReady } = useLoadingState();

  React.useEffect(() => {
    if (isPageReady) {
      animateContainerIn();
    }
  }, [isPageReady]);

  React.useEffect(() => {
    timeline.current.fromTo(
      ".mobile-hero-bg",
      60,
      {
        scale: 1.5,
      },
      {
        scale: 1,
      }
    );
  }, []);

  return (
    <Container ref={$container}>
      <div
        css={`
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;

          z-index: 2;
          overflow: hidden;
        `}
      >
        <img
          src={`https://primisgroup.imgix.net/pictures/DJI_00031.jpg?auto=format&h=1200&q=70`}
          alt="Aerial view"
          css={`
            object-fit: cover;

            width: 100%;
            height: 100%;

            display: block;
            transform-origin: center;
          `}
          className="mobile-hero-bg"
        />

        <div
          css={`
            position: absolute;
            top: -25vh;
            left: 0;
            right: 0;
            bottom: 0;
            background: radial-gradient(
              circle,
              rgba(13, 13, 13, 0) 0%,
              rgba(13, 13, 13, 1) 80%
            );
          `}
        />
      </div>

      <div
        css={`
          position: relative;
          z-index: 3;
        `}
      >
        <HeroContent />
      </div>
    </Container>
  );
}

function DesktopHero() {
  const { $container } = useHeroAnimation();

  return (
    <Container ref={$container}>
      <VideoContainer className="video-container">
        <Curtain className="curtain" />
        <VideoThing />
      </VideoContainer>
      <div
        css={`
          position: relative;
          top: 0;
          left: 0;
          right: 0;
          height: 100vh;
          z-index: 2;
        `}
      >
        <HeroContent />
      </div>
    </Container>
  );
}

function Hero() {
  const isDesktop = useMedia({ minWidth: 1280 });

  if (isDesktop) {
    return <DesktopHero />;
  }

  return <MobileHero />;
}

const Container = styled.div`
  position: relative;
  height: 100vh;

  .title {
    font-weight: 400;
    font-size: 3rem;
  }

  @media screen and (min-width: 1200px) {
    height: 200vh;

    .title {
      font-size: 102px;
    }
  }
`;

const Curtain = styled.div`
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  height: 100vh;
  right: 0;
  background: radial-gradient(transparent, rgba(0, 0, 0, 0)),
    rgba(0, 0, 0, 0.85);
  opacity: 1;
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default Hero;
